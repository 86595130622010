import { ChainId } from "@ichidao/ichi-sdk";

export enum NetworkType {
    EVM = 'evm',
    APTOS = 'aptos',
    MOVEMENT = 'movement',
  }
  
  export enum AptosChainId {
    MAINNET = 1,
    TESTNET = 2,
  }
  export enum MovementChainId {
    MAINNET = 126,
    TESTNET = 177,
  }

  export type GlobalChainId = ChainId | AptosChainId | MovementChainId;
  
  export type GlobalNetwork = {
    id: string;
    networkType: NetworkType;
    chainId: GlobalChainId;
    name: string;
};

export const SUPPORTED_GLOBAL_NETWORKS: GlobalNetwork[] = [
    {
        id: `${NetworkType.MOVEMENT}_${MovementChainId.MAINNET}`,
        networkType: NetworkType.MOVEMENT,
        chainId: MovementChainId.MAINNET,
        name: 'Movement',
    },
    {
        id: `${NetworkType.APTOS}_${AptosChainId.MAINNET}`,
        networkType: NetworkType.APTOS,
        chainId: AptosChainId.MAINNET,
        name: 'Aptos',
    },
    {
        id: `${NetworkType.APTOS}_${AptosChainId.TESTNET}`,
        networkType: NetworkType.APTOS,
        chainId: AptosChainId.TESTNET,
        name: 'Aptos Testnet',
    },
];

export enum InvestmentTypeID {
    ICHIVaultLiquidswap = 'ichi_vault_liquidswap',
    SatayMoveposition = 'satay_moveposition',
    SatayEchelon = 'satay_echelon',
    SatayLayerbank = 'satay_layerbank',
}

export enum LendingTypeID {
    SatayMoveposition = 'satay_moveposition',
    SatayEchelon = 'satay_echelon',
    SatayLayerbank = 'satay_layerbank',
}

export enum DexTypeID {
    ICHIVaultLiquidswap = 'ichi_vault_liquidswap',
}

export enum MarketTypeID {
    Lending = 'Lending',
    DEX = 'DEX',
}

export type InvestmentType = {
    type: InvestmentTypeID;
    name: string;
};

export type MarketType = {
    type: MarketTypeID;
    name: string;
}

export type LendingType = {
    type:LendingTypeID;
    name: string;
};

export type DexType = {
    type: DexTypeID;
    name: string;
};

// export type MarketType = {
//     type: MarketTypeID;
//     name: string;
// };

export const MARKET_TYPES = [
    {
        type: MarketTypeID.Lending,
        name: MarketTypeID.Lending.toString(),
    },
    {
        type: MarketTypeID.DEX,
        name: MarketTypeID.DEX.toString(),
    },
]

export const monitoredInvestmentTypes = [
    {
        type: InvestmentTypeID.ICHIVaultLiquidswap,
        name: "LiquidSwap",
    },
    {
        type: InvestmentTypeID.SatayMoveposition,
        name: "Moveposition",
    },
    {
        type: InvestmentTypeID.SatayEchelon,
        name: "Echelon",
    },
    {
        type: InvestmentTypeID.SatayLayerbank,
        name: "Layerbank",
    },
]

export const LENDING_TYPES = [
    {
        type: LendingTypeID.SatayMoveposition,
        name: "Moveposition",
    },
    {
        type: LendingTypeID.SatayEchelon,
        name: "Echelon",
    },
    {
        type: LendingTypeID.SatayLayerbank,
        name: "Layerbank",
    },
]

export const DEX_TYPES = [
    {
        type: DexTypeID.ICHIVaultLiquidswap,
        name: "LiquidSwap",
    },
]
