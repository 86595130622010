/* eslint-disable operator-linebreak */
import { StyledFlexRow } from 'common/styles/common.styles';
import React from 'react';
import GlobalChainSelector from '../GlobalChainSelector';
import { DexType, GlobalNetwork, LendingType, MarketType } from 'constants/networks';

type Props = {
  handleClick: (chain: GlobalNetwork, marketType: MarketType, market: LendingType | DexType) => void;
  currTab: MarketType;
  currChain: GlobalNetwork;
  currMarket: LendingType | DexType
};

const MonitorVaultsTableFilters: React.FC<Props> = (props) => {

  return (
    <StyledFlexRow justifyContent='space-between' alignItems='start'>
      <GlobalChainSelector 
        handleClick={props.handleClick} 
        currChain={props.currChain} 
        currMarketType={props.currTab} 
        currMarket={props.currMarket} />
        </StyledFlexRow>
  );
};

export default MonitorVaultsTableFilters;
