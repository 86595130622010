/* eslint-disable operator-linebreak */
import { StyledFlexRow } from 'common/styles/common.styles';
import React, { useState } from 'react';
import Select from 'react-select';
import { DEX_TYPES, DexType, GlobalNetwork, LENDING_TYPES, LendingType, MARKET_TYPES, MarketType, MarketTypeID, SUPPORTED_GLOBAL_NETWORKS } from 'constants/networks';

type Props = {
  handleClick: (chain: GlobalNetwork, marketType: MarketType, market: LendingType | DexType) => void;
  currChain: GlobalNetwork;
  currMarketType: MarketType;
  currMarket: LendingType | DexType
  isHidden?: boolean;
};

const ChainSelector: React.FC<Props> = (props) => {
  const [selectedChain, setSelectedChain] = useState(props.currChain);
  const [tab, setTab] = useState(props.currMarketType);
  const [market, setMarket] = useState(props.currMarket as (LendingType | DexType));

  if (props.isHidden){
    return (<></>);
  }
  const getChainName = (n: GlobalNetwork) => {
    return n.name;
  };
  const getChainSymbol = (c: GlobalNetwork) => {
    return c.name;
  };
  const onChainChange = (c: GlobalNetwork | null) => {
    if (c) {
      setSelectedChain(c);
      props.handleClick(c, tab, market);
    }
  }
  const getName = (marketType: MarketType) => {
    return marketType.name;
  };
  const getSymbol = (marketType: MarketType) => {
    return marketType.name;
  };
  const onChange = (marketType: MarketType | null) => {
    if (marketType) {
      setTab(marketType);
      if (marketType.type === MarketTypeID.Lending) {
        props.handleClick(selectedChain, marketType, LENDING_TYPES[0]);
        setMarket(LENDING_TYPES[0])
      } else {
        props.handleClick(selectedChain, marketType, DEX_TYPES[0]);
        setMarket(DEX_TYPES[0])
      }
    }
  }
  const getMarketName = (marketType: LendingType | DexType) => {
    return marketType.name;
  };
  const getMarketSymbol = (marketType: LendingType | DexType) => {
    return marketType.name;
  };
  const onMarketChange = (marketType: LendingType | DexType | null) => {
    if (marketType) {
      setMarket(marketType);
      props.handleClick(selectedChain, tab, marketType);
    }
  }

  let networks: GlobalNetwork[] = [];
  // Object.keys(SUPPORTED_NETWORKS).map((key) => networks.push(SUPPORTED_NETWORKS[key]));
  networks = SUPPORTED_GLOBAL_NETWORKS
    // .sort((a, b) => {return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1});

  return (
    <StyledFlexRow justifyContent='left' gap='10px'>
      Chain: 
      <Select<GlobalNetwork>
        className="basic-single w-52"
        classNamePrefix="vault-select"
        defaultValue={selectedChain}
        value={selectedChain}
        getOptionLabel={getChainName}
        getOptionValue={getChainSymbol}
        name={selectedChain.name}
        isClearable={false}
        options={networks}
        onChange={onChainChange}
      />
      Lending/DEX: 
      <Select<MarketType>
        className="basic-single w-52"
        classNamePrefix="vault-select"
        defaultValue={tab}
        value={tab}
        getOptionLabel={getName}
        getOptionValue={getSymbol}
        name={tab.name}
        isClearable={false}
        options={MARKET_TYPES}
        onChange={onChange}
      />
      {tab.name === MarketTypeID.Lending 
        ? ( <>
          Lending: 
          <Select<LendingType | DexType>
            className="basic-single w-52"
            classNamePrefix="vault-select"
            defaultValue={market}
            value={market}
            getOptionLabel={getMarketName}
            getOptionValue={getMarketSymbol}
            name={market.name}
            isClearable={false}
            options={LENDING_TYPES}
            onChange={onMarketChange}
          />
        </> )
        : ( <>
          DEX: 
          <Select<LendingType | DexType>
            className="basic-single w-52"
            classNamePrefix="vault-select"
            defaultValue={market}
            value={market}
            getOptionLabel={getMarketName}
            getOptionValue={getMarketSymbol}
            name={market.name}
            isClearable={false}
            options={DEX_TYPES}
            onChange={onMarketChange}
          />
        </>)
      }

    </StyledFlexRow>
  );
};

export default ChainSelector;
