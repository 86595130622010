/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import Image from 'common/components/Image';
import { Table, TableHeader } from 'common/models/table';
import { CanopyMetadata } from 'common/models/monitorVaults';
import { StyledFlexAlignCenter, StyledTable, StyledTableHeadingTr, StyledTableTh } from 'common/styles/common.styles';
import React from 'react';
import Tooltip from '../Tooltip/Tooltip';
import { TooltipWrapper } from '../Tooltip/tooltip.style';
import { DexType, GlobalNetwork, LendingType, MarketType } from 'constants/networks';
import CanopyTablePosition from './CanopyTablePosition';

type Props = {
  table: Table;
  canopyMetadata: CanopyMetadata[];
  marketFilter: LendingType | DexType;
  chainFilter: GlobalNetwork;
  marketTypeFilter: MarketType;
  handleSorting: (sortBy: string) => void;
  criterion: string;
};

const CanopyTable: React.FC<Props> = (props) => {
  const metadata = props.canopyMetadata;

  const compareVaults = (a: CanopyMetadata, b: CanopyMetadata) => {
    let criteria: number = 0
    if (props.criterion === 'displayname'){ criteria = (b.displayName.toLowerCase() > a.displayName.toLowerCase()) ? -1 : 1; }
    if (props.criterion === 'tvl'){ criteria = b.tvl  - a.tvl ; }
    if (props.criterion === 'deposittokenratio'){ criteria = b.pendingPct - a.pendingPct; }
    return criteria;
  };
    
  const filteredMetadata = metadata
    .filter((item) => (item.chainId === props.chainFilter.chainId 
      && item.investmentType === props.marketFilter.type
    ))
    .sort((a, b) => compareVaults(a, b))

  const getHeaderElem = (header: TableHeader) => {
    return header.sortBy ? (
      <div 
        className="mr-5 cursor-pointer underline" 
        onClick={() => {props.handleSorting(header.sortBy ?? '');}}>
        {header.name}
      </div>
    ) : (
      <div className="mr-5">{header.name}</div>
    );
  };


  return (
    <>
      <StyledTable>
        <thead>
          <StyledTableHeadingTr className="table-border-bottom">
            {props.table?.headers?.map((header) => (
              <StyledTableTh key={header.name} className="sub-text-color">
                <StyledFlexAlignCenter>
                  {getHeaderElem(header)}
                  {header.helpText && (
                    <TooltipWrapper>
                      <Tooltip message={header.helpText} position="top" bubbleSize={{ width: '200px' }}>
                        <Image className="icon-help" alt={header.helpText} />
                      </Tooltip>
                    </TooltipWrapper>
                  )}
                </StyledFlexAlignCenter>
              </StyledTableTh>
            ))}
          </StyledTableHeadingTr>
        </thead>
        <tbody>
          {filteredMetadata?.map((item) => (
            <CanopyTablePosition 
              key={item.id} 
              vault={item}
            />
          ))}
        </tbody>
      </StyledTable>
    </>
  );
};

export default CanopyTable;
