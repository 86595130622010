import { useQuery } from '@apollo/client';
import SpinnerContainer from 'common/components/SpinnerContainer';
import { QueryItemsResponse, QueryName } from 'common/models/graphql';
import { REFRESH_INTERVALS } from 'constants/constants';
import React, { useState } from 'react';
import { CanopyMetadata } from 'common/models/monitorVaults';
import { ContentTableWrapper } from './app.style';
import CanopyFilters from 'common/components/CanopyTable/CanopyFilters';
import CanopyTable from 'common/components/CanopyTable/CanopyTable';
import { DexType, GlobalNetwork, LENDING_TYPES, LendingType, MARKET_TYPES, MarketType, SUPPORTED_GLOBAL_NETWORKS } from 'constants/networks';
import { listCanopyMetadataQuery } from 'graphql/queries/listCanopyVaults.query';

type Props = {
};
export enum LiquidityAmountInVaults {
  NoLiquidity = 0,
  OnlyWithLiquidity = 1,
  Both = 2,
}

const CanopyContainer: React.FC<Props> = () => {
  const [selectedChain, setSelectedChain] = useState(SUPPORTED_GLOBAL_NETWORKS[0]);
  const [tab, setTab] = useState(MARKET_TYPES[0]);
  const [market, setMarket] = useState(LENDING_TYPES[0] as (LendingType | DexType));
  const [criterion, setCriterion] = useState<string>('tvl');

    const {
    loading: loadingCanopyMetadata,
    error: errorCanopyMetadata,
    data: dataCanopyMetadata,
    networkStatus: networStatusCanopyMetadata,
    refetch: refetchCanopyMetadata
  } = useQuery<QueryItemsResponse<CanopyMetadata>>(listCanopyMetadataQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: REFRESH_INTERVALS[QueryName.listCanopyMetadata]
  });

  const canopyMetadata = dataCanopyMetadata?.listCanopyMetadata.items || [];

  const table =  {
    'headers': [
      {
        'name': 'Name',
        'sortBy': 'displayname'
      },
      {
        'name': 'Pend. Dep.',
        'sortBy': 'pendingdepositratio'
      },
      {
        'name': 'Balance',
        'helpText': ''
      },
      {
        'name': 'TVL',
        'sortBy': 'tvl'
      },
      {
        'name': 'APR',
        'helpText': '',
      },
      {
        'name': 'Reward APR',
        'helpText': '',
      },
    ]
  };

  if (errorCanopyMetadata) {
    console.error(errorCanopyMetadata);
  }

  return (
    <SpinnerContainer isLoading={loadingCanopyMetadata}>
      <CanopyFilters 
        handleClick={(chain: GlobalNetwork, marketType: MarketType, market: LendingType | DexType) => {setTab(marketType); setMarket(market); setSelectedChain(chain)}} 
        currTab={tab} 
        currChain={selectedChain}
        currMarket={market}
      />
      <ContentTableWrapper>
        <CanopyTable 
          table={table} 
          canopyMetadata={canopyMetadata}
          chainFilter={selectedChain} 
          marketTypeFilter={tab}
          marketFilter={market}
          handleSorting={(sortBy: string) => setCriterion(sortBy)}
          criterion={criterion} />
      </ContentTableWrapper>
    </SpinnerContainer>
  );
};

export default CanopyContainer;
