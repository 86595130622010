/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import { CanopyMetadata } from 'common/models/monitorVaults';
import { StyledTableBodyTr, StyledTd } from 'common/styles/common.styles';
import React from 'react';
import FormatNumber from '../FormatPercent/FormatNumber';
import { StyledMonitorVaultsCoinName } from './monitorVaultsTable.style';
import CanopyTablePositionDetails from './CanopyTablePositionDetails';
import { NumberUtils } from 'utils/numberUtils';
import { InvestmentTypeID } from 'constants/networks';

type Props = {
  vault: CanopyMetadata;
};

const CanopyTablePosition: React.FC<Props> = (props) => {
  const [isVaultSelected, setIsVaultSelected] = React.useState(false);
  const toggleSelected = () => {
    setIsVaultSelected(!isVaultSelected);
  };

  const vault = props.vault;
  const isPaused = vault.paused;
  const vaultNameClass = isPaused ? 'alert-text-color' : '';

  let depositTokenBalance = NumberUtils.BNtoNumberWithoutDecimals(vault.token0Balance, vault.decimals0)
  if (vault.investmentType === InvestmentTypeID.ICHIVaultLiquidswap && vault.allowToken1) {
    depositTokenBalance = NumberUtils.BNtoNumberWithoutDecimals(vault.token1Balance, vault.decimals1)
  }


  return (
    <>
      <StyledTableBodyTr
        className={isVaultSelected ? 'no-border-bottom cursor-pointer' : 'table-border-bottom cursor-pointer'}
        key={vault.id}
        onClick={toggleSelected}
      >
        <StyledTd className="primary-text-color">
          <StyledMonitorVaultsCoinName className="primary-text-color">
            <span className={vaultNameClass}>{vault.displayName}</span>
            {isPaused && (<span className={vaultNameClass}>paused</span>)}
          </StyledMonitorVaultsCoinName>
        </StyledTd>

        <StyledTd className="primary-text-color pb-3 text-small">
          <FormatNumber value={vault.pendingPct} abbreviate />
        </StyledTd>

        <StyledTd className="primary-text-color pb-3 text-small">
          <FormatNumber value={depositTokenBalance} abbreviate />
        </StyledTd>

        <StyledTd className="primary-text-color pb-3 text-small">
          <FormatNumber value={Number(vault.tvl)} abbreviate />
        </StyledTd>

        <StyledTd className="primary-text-color pb-3 text-small">
          <FormatNumber value={Number(vault.apr)} abbreviate />
        </StyledTd>

        <StyledTd className="primary-text-color pb-3 text-small">
          <FormatNumber value={Number(vault.rewardApr)} abbreviate />
        </StyledTd>

      </StyledTableBodyTr>
      <CanopyTablePositionDetails
        isShown={isVaultSelected}
        vault={props.vault}
      />
    </>
  );
};

export default CanopyTablePosition;
