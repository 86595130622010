/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import { CanopyMetadata } from 'common/models/monitorVaults';
import { StyledFlexRow, StyledTableBodyTr, StyledTdDetails } from 'common/styles/common.styles';
import { InvestmentTypeID } from 'constants/networks';
import React from 'react';
import { NumberUtils } from 'utils/numberUtils';
import FormatNumber from '../FormatPercent/FormatNumber';

type Props = {
  vault: CanopyMetadata;
  isShown: boolean;
};

const CanopyTablePositionDetails: React.FC<Props> = (props) => {
  const vault = props.vault
  const metadata = vault.additionalMetadata
  let depositTokenBalance = NumberUtils.BNtoNumberWithoutDecimals(vault.token0Balance, vault.decimals0)
  let quoteTokenBalance = NumberUtils.BNtoNumberWithoutDecimals(vault.token1Balance, vault.decimals1)
  if (vault.investmentType === InvestmentTypeID.ICHIVaultLiquidswap && vault.allowToken1) {
    depositTokenBalance = NumberUtils.BNtoNumberWithoutDecimals(vault.token1Balance, vault.decimals1)
    quoteTokenBalance = NumberUtils.BNtoNumberWithoutDecimals(vault.token0Balance, vault.decimals0)
  }

  if (props.isShown) {
    return (
      <>
        <StyledTableBodyTr key={`${props.vault.id}details1`} className="row-secondary">
          <StyledTdDetails colSpan={6}>
            Address: <b>{vault.networkAddress}</b><br />
            Deposit Token: <b>{vault.token0}</b><br />
            Reward Pools: {(vault.rewardPools && vault.rewardPools.length > 0) && vault.rewardPools.map((p) => (<><b>{vault.networkAddress}</b><br /></>))}
            <br />
            {vault.investmentType !== InvestmentTypeID.ICHIVaultLiquidswap && (
              <StyledFlexRow gap='8px'>
                Debt Limit:
                <FormatNumber value={Number(vault.debtLimit)} abbreviate />
              </StyledFlexRow>
            )}
          </StyledTdDetails>
        </StyledTableBodyTr>
        {vault.investmentType === InvestmentTypeID.ICHIVaultLiquidswap && (
        <StyledTableBodyTr key={`${props.vault.id}details2`} className="row-tertiary table-border-bottom" >
          <StyledTdDetails colSpan={5}>
            Deposit Token Balance: <b>{depositTokenBalance}</b><br />
            Quote Token Balance: <b>{quoteTokenBalance}</b><br />
            {metadata[0].key}: <b>{metadata[0].item}</b><br />
            {metadata[1].key}: <b>{metadata[1].item}</b><br />
            {metadata[2].key}: <b>{metadata[2].item}</b><br />
            Pool Bin Step: <b>{metadata[2].item.substring(metadata[2].item.lastIndexOf('X')+1)}</b><br />
          </StyledTdDetails>
        </StyledTableBodyTr>
      )}
      </>
    );
  // eslint-disable-next-line no-else-return
  } else {
    return (<></>);
  }
};

export default CanopyTablePositionDetails;
