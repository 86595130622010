import App from 'App';
import CanopyContainer from 'containers/CanopyContainer';
import React from 'react';

const Farm: React.FC<{}> = () => {
  return (
    <App>
      <CanopyContainer onlyNeedRebalance={false} />
    </App>
  );
};

export default Farm;
