import { gql } from '@apollo/client';

export const listCanopyMetadataQuery = gql`
  query LisCanopyMetadata {
    listCanopyMetadata {
      items {
        id
        chainId
        networkAddress
        displayName
        investmentType
        networkType
        riskScore
        priority
        description
        iconURL
        labels
        rewardPools
        additionalMetadata {
          key
          item
        }
        paused
        token0
        token1
        allowToken0
        allowToken1
        decimals0
        decimals1
        tvl
        token0Balance
        token1Balance
        apr
        rewardApr
        pendingPct
        debtLimit
      }
    }
  }
`;
